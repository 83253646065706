<script>
export default {
  name: "index",
  data(){
    return{
      apiUrl:{
        list:'/api/history/page',
        newList: '/api/bxs/colloidal/list',
        del:'/api/bxs/remove/',
        system: '/api/setting/getSetting',
        history: '/api/history/saveOrUpdate',
        upload: '/api/bxs/colloidal/upload',
        update: '/api/bxs/colloidal/isUpload/', // 新上传省平台接口
        dict: '/api/bxs/dict/',
      },
      queryParams:{
        current: 1,
        size: 10,
      },
      dataList: [],
      loggingList:[],
      loading: false,
      // 当前页
      currentPage: 1,
      // 总条数
      total: 0,
      startNum: 0,
      endNum: 10,
      pageNum: 1,
      numberItems: 10,
      multiple:[],
      uploadStatus:[
        {label:'已上传',value: 1},
        {label:'未上传',value: 0}
      ],
      decideOutcome: [
        {value: '1', label: "合格"},
        {value: '0', label: "不合格"},
        {value: '2', label: "无效"}
      ],
      // 系统配置信息
      systemForm:{},
      systemState: false,
      detailsShow: false,
      detailsTitle: '',
      detailsList:[],
      // 字典
      projectList:[], // 项目类型
      methodList:[], // 检测方法
      cardList:[], // 试剂卡
      determine:[], // 判定
      authTypeList: [], // 限定
      // 更多
      moreShow: false,
      // 样品
      sampleShow: false,
      sampleTitle: '',
      sampleForm:{},
      // 检测时间
      time:'',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近半年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 182);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '今年',
          onClick(picker) {
            const end = new Date();
            const start = new Date(end.getFullYear(), 0, 1);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  created() {
    this.getSystem();
    console.log("this.$route.query",this.$route.query)
    const query = this.$route.query;
    if (Object.keys(query).length > 0) {
      this.recheck(query);
    } else {
      this.getList();
    }
    this.getDict();
  },
  methods:{
    getList(){
      this.loading = true;
      this.postApiConfig(this.apiUrl.newList,this.queryParams).then((res)=>{
        this.loading = false;
        this.loggingList = res.data.records
        this.total = res.data.total
      })
    },
    recheck(value){
      console.log(value)
      this.queryParams.decideOutcome = value.decideOutcome
      this.time= [value.startTime,value.endTime]
      this.queryParams.startTime = value.startTime;
      this.queryParams.endTime = value.endTime;
      if(value.authType){
        this.queryParams.authType = value.authType;
        this.moreShow = true;
      }
      this.getList();
    },
    // 获取字典
    async getDict() {
      try {
        const requests = [
          this.getApiConfig(this.apiUrl.dict, 'detection_project_type'),
          this.getApiConfig(this.apiUrl.dict, 'project_detection_methods'),
          this.getApiConfig(this.apiUrl.dict, 'reagent_card_type'),
          this.getApiConfig(this.apiUrl.dict, 'sys_detection_determine'),
          this.getApiConfig(this.apiUrl.dict, 'reagent_card_specs'),
        ];

        const responses = await Promise.all(requests);

        this.projectList = responses[0].data;
        this.methodList = responses[1].data;
        this.cardList = responses[2].data;
        this.determine = responses[3].data;
        this.authTypeList = responses[4].data;
      } catch (error) {
        console.error('获取字典数据时发生错误:', error);
        // 可以在这里处理错误情况，比如提示用户或者回退操作
      }
    },
    // 获取系统配置信息
    getSystem(){
      this.postApiConfig(this.apiUrl.system).then((res)=>{
        let data = res.data.detectionSetting
        if (data) {
          data.checkTime = data.checkTime.replace(/-/g, '');
          this.systemForm = res.data.detectionSetting;
          this.systemState = true;
        }
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      if (this.time) {
        this.queryParams.startTime = this.time[0]
        this.queryParams.endTime = this.time[1]
      }
      this.queryParams.current = 1;
      this.queryParams.size = 10;
      if(!this.queryParams.decideOutcome){
        delete this.queryParams.decideOutcome
      }
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {
        current: 1,
        size: 10,
      };
      this.time = '';
      const path = this.$route.path; // 先获取路由路径
      this.$router.push(path); // 再跳转路由路径，query参数没带，被清除掉
      this.currentPage = 1;
      this.handleQuery();
    },
    oneUpload(){
      if (this.multiple.length > 0) {
        this.uploadData(this.multiple)
      } else {
        this.$message({
          type: "error",
          message: "请选择需要上传的样品",
        });
      }
    },
    handleSelectionChange(selection){
      this.multiple = selection
    },
    selectable(row) {
      if ( row.isUpload == 1 || row.testingResult.includes('2')) {
        return false;
      } else {
        return true;
      }
    },
    async uploadData(row) {
      let uploadArr = row
      console.log(this.systemState)
      if (!this.systemState) {
        this.$confirm("请前往系统设置填写信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$router.push("/system");
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
        return;
      }
      // 确保uploadArr为数组
      uploadArr = Array.isArray(uploadArr) ? uploadArr : [uploadArr];
      this.$modal.loading("正在上传至省平台，请稍候...");
      console.log("uploadArr",uploadArr)
      try {
        // 并行处理所有上传请求
        const uploadPromises = uploadArr.map(async (item) => {
          const uploadPayload = this.prepareUploadPayload(item);
          console.log("uploadPayload",uploadPayload)

          const uploadResponse = await this.getApiConfig(this.apiUrl.update, item.id)
              .catch(err => { // 捕获并处理上传请求的异常
                console.error("上传请求错误:", err);
                throw err; // 重新抛出错误，以便Promise.all能够感知
              });
          this.$modal.closeLoading();
          if (uploadResponse.code === 200) {
            console.log("uploadResponse",uploadResponse)
            this.$message.success('上传成功')
            await this.getList();
          } else {
            this.$message.error(uploadResponse.msg)
            console.error("上传至省平台失败，响应码:", uploadResponse.code);
          }
        });
        // 等待所有上传操作完成
        await Promise.all(uploadPromises);
        this.$modal.closeLoading();
      } catch (err) {
        this.$modal.closeLoading();
        console.error("上传过程中遇到错误:", err);
        // 可以根据需要添加统一的错误处理逻辑
      }
    },
    async batchUpload(){
      if (this.multiple.length == 0) {
        this.$message({
          type: "error",
          message: "请选择需要上传的样品",
        });
        return false;
      }
      let ids = this.multiple.map(item => item.id)
      this.$modal.loading("正在上传至省平台，请稍候...");
      const uploadResponse = await this.getApiConfig(this.apiUrl.update, ids.toString())
      if (uploadResponse.code === 200) {
        await this.getList();
        this.$message.success("上传成功");
        this.$modal.closeLoading();
      } else {
        this.$modal.closeLoading();
        console.error("上传至省平台失败，响应码:", uploadResponse.code);
      }
    },
    // 辅助函数
    prepareUploadPayload(item) {
      const batchSn = this.getTimeRandom();

      let parameter = JSON.parse(item.details)

      let projectList = parameter.map(item => ({
        ocrImage: item.ocrImage,
        grayImage: item.grayImage,
        cValue: item.cValue,
        tValue: item.tValue,
        projectName: item.projectname,
        projectId: item.projectId,
        result: item.detection_result,
        resultValue: item.value,
        serialNumber: item.testnum
      }));

      return {
        batchSn, // 检测批次
        qrKey: item.qrKey, // 二维码编号
        projectId: item.projectId, // 项目ID
        projectName: item.projectName, //项目名称
        sampleId: item.sampleId, //样品名称
        sampleName: item.sampleName, //样品名称
        sampleNumber: item.sampleNumber, //样品编号
        sampleType: item.sampleType, //样品类型
        historyId: item.id, // 历史ID
        judgeName: '', // 判断名称
        detectionMethod: item.detectionMethod, // 检测方法 1：比色法，2：消线法
        checkTime: item.checkTime, // 检测时间
        projectList: projectList, // 检测数据详情
      };
    },
    // 特定格式的随机时间字符串
    getTimeRandom() {
      const current = new Date();
      const formattedDate = `${current.getFullYear().toString().slice(-2)}${addZero(current.getMonth() + 1)}${addZero(current.getDate())}`;
      const randomNumber = Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000;
      const result = `BS_${formattedDate}${randomNumber}`;
      return result;
      function addZero(num) {
        return num < 10 ? '0' + num : num;
      }
    },
    // 删除
    handleDelete(row){
      this.$confirm("此操作将永久删除 “ " + row.sampleName + " - " + row.entryName + " ”, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.$modal.loading("正在请求数据，请稍后...");
        this.getApiConfig(this.apiUrl.del,row.id).then((res)=>{
          this.$modal.closeLoading();
          if(res.code == 200){
            this.getList();
            this.$message.success("删除成功！")
          }else{
            this.$message.error(res.msg)
          }
        }).catch(()=>{
          this.$modal.closeLoading();
        })
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.current = val;
      this.getList();
    },
    handleSizeChange(val){
      console.log("size-change",val);
      this.queryParams.size = val;
      this.getList();
    },
    changeResult(){
      this.$forceUpdate()
    },
    changeAuthType(){
      this.$forceUpdate()
    },
    // 查看样品
    lookSample(row){
      this.sampleShow = true;
      this.sampleTitle = row.sampleName + " - " + "样品详细数据";
      this.sampleForm = {
        cargoSource: row.cargoSource,
        cargoQuantity: row.cargoQuantity,
        cargoSampleQuantity: row.cargoSampleQuantity,
        samplerUser: row.samplerUser,
        cargoOwner: row.cargoOwner,
        reagentProduction: row.reagentProduction,
        remark: row.remark,
      }
    },
    // 查看检测结果
    lookDetails(row){
      console.log(JSON.parse((row.details)))
      this.detailsShow = true;
      this.detailsTitle = row.sampleName + " - " + "检测结果";
      this.detailsList = JSON.parse((row.details));
    },
    // 格式化
    formatterProjectType(row){
      const res = this.projectList.find((item) => item.dictValue == row.projectType);
      return res ? res.dictLabel : null;
    },
    formatterCardType(row){
      const res = this.cardList.find((item) => item.dictValue == row.cardType);
      return res ? res.dictLabel : null;
    },
    formatterDetectionResult(data){
      let str = "";
      switch (parseInt(data)){
        case 1:
          str = "合格";
          break;
        case 2:
          str = "无效";
          break;
        case 0:
          str = "不合格";
          break;
      }
      return str
    },
    expand(){
      this.moreShow = !this.moreShow
      if(!this.moreShow){
        this.queryParams = {
          current: 1,
          size: 10,
        }
        // this.getList();
      }
    },
  },
}
</script>

<template>
  <div class="history-page">
    <div>
      <el-form ref="queryForm" :inline="true" :model="queryParams">

        <div style="display: inline;" v-if="moreShow">
          <el-form-item label="检测项目类型">
            <el-select v-model="queryParams.projectType" placeholder="请选择检测项目类型" size="small" clearable style="width: 170px" @change="changeResult">
              <el-option v-for="(item,index) in projectList" :key="index" :label="item.dictLabel" :value="parseInt(item.dictValue)"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="样品名称">
            <el-input v-model="queryParams.sampleName" clearable placeholder="请输入样品名称" size="small" @keyup.enter.native="handleQuery"></el-input>
          </el-form-item>

          <el-form-item label="试剂卡类型">
            <el-select v-model="queryParams.cardType" placeholder="请选择试剂卡类型" size="small" clearable style="width: 170px" @change="changeResult">
              <el-option v-for="(item,index) in cardList" :key="index" :label="item.dictLabel" :value="parseInt(item.dictValue)"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="试剂卡编号">
            <el-input v-model="queryParams.qrKey" clearable placeholder="请输入试剂卡编号" size="small" @keyup.enter.native="handleQuery"></el-input>
          </el-form-item>

          <el-form-item label="检测方法">
            <el-select v-model="queryParams.detectionMethod" placeholder="请选择检测方法" size="small" clearable style="width: 170px" @change="changeResult">
              <el-option v-for="(item,index) in methodList" :key="index" :label="item.dictLabel" :value="parseInt(item.dictValue)"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="限用类型">
            <el-select v-model="queryParams.authType" placeholder="请选择限用类型" size="small" clearable style="width: 170px" @change="changeAuthType">
              <el-option v-for="(item,index) in authTypeList" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </div>

        <el-form-item label="检测时间">
          <el-date-picker
              v-model="time"
              size="small"
              type="daterange"
              unlink-panels
              start-placeholder="开始日期"
              range-separator="至"
              end-placeholder="结束日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions"
              align="right"
              style="width: 100%;"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="检测结果">
          <el-select v-model="queryParams.decideOutcome" placeholder="请选择检测结果" size="small" clearable style="width: 170px" @change="changeResult">
            <el-option v-for="(item,index) in decideOutcome" :key="index" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="上传状态">
          <el-select v-model="queryParams.isUpload" placeholder="请选择上传状态" size="small" clearable style="width: 170px">
            <el-option v-for="(item,index) in uploadStatus" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button icon="el-icon-search" type="primary" size="small" @click="handleQuery">搜索</el-button>
          <el-button icon="el-icon-refresh" size="small" @click="resetQuery">重置</el-button>
          <el-button icon="el-icon-sort" type="info" size="small" @click="expand">{{ moreShow ? "收起" : "更多" }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 按钮操作 -->
    <div>
      <el-button icon="el-icon-finished" type="success" size="small" @click="batchUpload">一键上传</el-button>
    </div>
    <!-- 表格 -->
    <div style="margin-top: 10px;">
      <el-table ref="table" v-loading="loading" :data="loggingList" border @selection-change="handleSelectionChange">
        <el-table-column :selectable="selectable" align="center" fixed type="selection" width="55"/>
        <el-table-column align="center" label="项目名称" prop="sampleName" fixed width="140">
          <template slot-scope="scope">
            <div v-if="scope.row.projectType == 1">
              <el-tooltip class="item" effect="dark" content="点击查看详细数据" placement="top">
                <el-button type="text" @click="lookSample(scope.row)">{{ scope.row.entryName }}</el-button>
              </el-tooltip>
            </div>
            <div v-else>
              {{ scope.row.entryName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="样品名称" prop="sampleName" fixed width="140" show-overflow-tooltip/>
        <el-table-column align="center" label="检测项目类型" prop="" :formatter="formatterProjectType" width="120"/>
        <el-table-column align="center" label="限用类型" prop="detectionMethod" width="100">
          <template slot-scope="scope">
            <dict-tag :options="authTypeList" :value="scope.row.authType"/>
          </template>
        </el-table-column>
        <el-table-column align="center" label="试剂卡类型" prop="" :formatter="formatterCardType" width="120"/>
        <el-table-column align="center" label="检测方法" prop="">
          <template slot-scope="scope">
            <el-tag v-show="scope.row.detectionMethod" :type="Number(scope.row.detectionMethod) === 1 ? 'warning' : 'info'">
              {{ Number(scope.row.detectionMethod) === 1 ? '比色法' : '消线法' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="检测结果详情" prop="detectionMethod" width="180">
          <template slot-scope="scope">
            <div style="width: 100%;display: flex;flex-direction: column;gap: 3px 0;">
              <div v-for="(item,index) in JSON.parse(scope.row.details)" :key="index" style="display: flex;justify-content: flex-end;gap: 0 5px;">
                <span>{{ item.projectname + "：" }}</span>
                <dict-tag :options="determine" :value="item.detectionResult"/>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="试剂卡编号" prop="qrKey" width="180" show-overflow-tooltip/>
        <el-table-column align="center" label="受检单位" prop="unit" width="150" show-overflow-tooltip/>
        <el-table-column align="center" label="检测单位" prop="inspectionUnit" width="150" show-overflow-tooltip/>
        <el-table-column align="center" label="数据来源" prop="inspectionUnit" width="100">
          <template slot-scope="scope">
            {{ Number(scope.row.channel) === 1 ? 'APP' : 'PC' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="检测时间" prop="creationTime" width="180" show-overflow-tooltip/>
        <el-table-column align="center" label="是否上传" prop="" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isUpload == 1" type="success">已上传</el-tag>
            <el-tag v-else type="danger">未上传</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="省平台状态" prop="" width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.uploadStatus == 1" type="success">上传成功</el-tag>
            <el-tag v-else-if="scope.row.uploadStatus == 2" type="danger">上传失败</el-tag>
            <el-tag v-else type="info">未上传</el-tag>
          </template>
          </el-table-column>
        <el-table-column align="center" label="省平台失败原因" prop="uploadRemark" width="250" show-overflow-tooltip/>
        <el-table-column align="center" label="操作" prop="" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button icon="el-icon-edit" size="mini" type="primary" @click="uploadData(scope.row)" v-if="scope.row.isUpload == 0 && !scope.row.testingResult.includes('2')">
              上传
            </el-button>
            <el-button icon="el-icon-delete" size="mini" type="danger" @click="handleDelete(scope.row)" v-if="scope.row.isUpload == 0">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div style="margin-top: 10px;display: flex;justify-content: flex-end;">
      <el-pagination
          v-show="total > 0"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="10"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <!-- 检测结果详情 -->
    <div>
      <el-dialog :title="detailsTitle" :visible.sync="detailsShow" width="850px">
        <div>
          <el-table :data="detailsList" border style="width: 100%">
            <el-table-column align="center" prop="projectname" label="项目名称" width="150" show-overflow-tooltip/>
            <el-table-column align="center" prop="testnum" label="样品编号" width="180" show-overflow-tooltip/>
            <el-table-column align="center" prop="detectionResult" label="检测结果"/>
            <el-table-column align="center" prop="value" label="检测结果值">
              <template slot-scope="scope">
                {{ parseFloat(scope.row.value).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="cValue" label="C值">
              <template slot-scope="scope">
                {{ parseFloat(scope.row.cValue).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="tValue" label="T值">
              <template slot-scope="scope">
                {{ parseFloat(scope.row.tValue).toFixed(2) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="detailsShow = false">关 闭</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 查看样品 -->
    <div>
      <el-dialog :title="sampleTitle" :visible.sync="sampleShow" width="650px">
        <div>
          <el-form :model="sampleForm" ref="drawerForm" label-width="120px">
            <el-form-item label="生猪来源" prop="cargoSource">
              <el-input v-model="sampleForm.cargoSource" placeholder="生猪来源" disabled></el-input>
            </el-form-item>
            <el-form-item label="生猪头数" prop="cargoQuantity">
              <el-input v-model="sampleForm.cargoQuantity" placeholder="生猪头数" disabled></el-input>
            </el-form-item>
            <el-form-item label="抽样头数" prop="cargoSampleQuantity">
              <el-input v-model="sampleForm.cargoSampleQuantity" placeholder="抽样头数" disabled></el-input>
            </el-form-item>
            <el-form-item label="抽样人员" prop="samplerUser">
              <el-input v-model="sampleForm.samplerUser" placeholder="抽样人员" disabled></el-input>
            </el-form-item>
            <el-form-item label="畜（货）主" prop="cargoOwner">
              <el-input v-model="sampleForm.cargoOwner" placeholder="畜（货）主" disabled></el-input>
            </el-form-item>
            <el-form-item label="试剂生产企业" prop="reagentProduction">
              <el-input v-model="sampleForm.reagentProduction" placeholder="试剂生产企业" disabled></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="sampleForm.remark" disabled></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="sampleShow = false">关 闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<style scoped lang="scss">
.history-page{
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.result-adopt{
  color: #67c23a;
}
.result-not-passed{
  color: #fe0000;
}
.result-invalid{
  color: #C0C4D5;
}
</style>
